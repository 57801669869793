import { createApp } from 'vue'
import App from './App.vue'
import VueTippy from 'vue-tippy'

import initMainMenu from '../MainMenu/main'
import initCountrySwitcher from '../CountrySwitcher/main'
import initLanguageSwitcher from '../LanguageSwitcher/main'
import initMobileMenu from '../MobileMenu/main'
import initQuickLinks from '../QuickLinks/main'
import initSocialLinks from '../SocialLinks/main'
import initSocialSharing from '../SocialSharing/main'
import initFooter from '../Footer/main'
import initHeroSlider from '../HeroSlider/main'
import initTextTeaser from '../TextTeaser/main'
import initSalesContainer from '../SalesContainer/main'
import initContactForm from '../ContactForm/main'
import initContactList from '../ContactList/main'
import initDownloadBox from '../DownloadBox/main'
import initProductArea from '../ProductArea/main'
import initProductSlider from '../ProductSlider/main'
import initModal from '../Modal/main'
import initAccordion from '../Accordion/main'
import initIFrameResizer from '../IFrameResizer/main'
import initSidebarSlider from '../SidebarSlider/main'
import initProductDetailTabs from '../ProductDetailTabs/main'
import initGoogleMaps from '../GoogleMaps/main'
import initContentTabs from '../ContentTabs/main'
import initFAQList from '../FAQList/main'
import initJobsList from '../JobsList/main'
import initDownloadList from '../DownloadList/main'
import initDownloadButton from '../DownloadButton/main'
import initWorldWideList from '../WorldWideList/main'
import initQuickAddBox from '../QuickAddBox/main'
import initWishList from '../WishList/main'
import initMondayWebforms from '../MondayWebforms/main'
import initShopCart from '../ShopCart/main'
import initResultList from '../ResultList/main'
import initElasticSearch from '../ElasticSearch/main'
import initBranchDetailTabs from '../BranchDetailTabs/main'
import initBranchList from '../BranchList/main'
import initBranchApp from '../BranchApp/main'
import initNaviSelectCascading from '../NaviSelectCascading/main'
import initShopLogin from '../ShopLogin/main'
import initConfigurator from '../Configurator/main'
import initOrderForm from '../OrderForm/main'
import initCareerIFrame from '../CareerIFrame/main'
import initNewsList from '../NewsList/main'
import initNewsletterForm from '../NewsletterForm/main'
import initNewsletterQuickSubscription from '../NewsletterQuickSubscription/main'
import initRFQForm from '../RFQForm/main'
import initD365Form from '../D365Form/main'
import initProductAreaControlledCarousel from '../ProductAreaControlledCarousel/main'
import initButton from '../Button/main'
import initShopIFrame from '../ShopIFrame/main'
import initShopCountrySwitcher from '../ShopCountrySwitcher/main'
import initCookieDisclaimer from '../CookieDisclaimer/main'
import initGeoIPOverlay from '../GeoIPOverlay/main'
import initImageMagnifier from '../ImageMagnifier/main'
import initFlipCard from '../FlipCard/main'
import initRotatingCarousel from '../RotatingCarousel/main'
import initKPIS from '../KPIS/main'
import initVideoImage from '../VideoImage/main'
import initScrollNavigation from '../ScrollNavigation/main'
import initHRTeaser from '../HRTeaser/main'

import initIndividualPrice from '../IndividualPrice/main'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const init = () => {
  const app = createApp({
    render: (h) => h(App)
  })

  app.use(VueTippy)
  app.mount('#app')

  initMainMenu()
  initCountrySwitcher()
  initLanguageSwitcher()
  initMobileMenu()
  initQuickLinks()
  initSocialLinks()
  initSocialSharing()
  initFooter()
  initHeroSlider()
  initTextTeaser()
  initSalesContainer()
  initContactForm()
  initContactList()
  initDownloadBox()
  initProductArea()
  initProductSlider()
  initModal()
  initAccordion()
  initIFrameResizer()
  initSidebarSlider()
  initProductDetailTabs()

  // #START
  // ? also running in tabs
  initGoogleMaps()
  initFlipCard()
  initRotatingCarousel()
  initContentTabs()
  // #END

  initFAQList()
  initJobsList()
  initDownloadList()
  initDownloadButton()
  initWorldWideList()
  initQuickAddBox()
  initWishList()
  initMondayWebforms()
  initShopCart()
  initResultList()
  initElasticSearch()
  initBranchDetailTabs()
  initBranchList()
  initBranchApp()
  initNaviSelectCascading()
  initShopLogin()
  initConfigurator()
  initOrderForm()
  initCareerIFrame()
  initNewsList()
  initNewsletterForm()
  initNewsletterQuickSubscription()
  initRFQForm()
  initD365Form()
  initButton()
  initShopIFrame()
  initShopCountrySwitcher()
  initCookieDisclaimer()
  initGeoIPOverlay()
  initImageMagnifier()
  initProductAreaControlledCarousel()
  initKPIS()
  initVideoImage()
  initScrollNavigation()
  initHRTeaser()

  initIndividualPrice()
}

if (document.body.classList.contains('cookiebannerEnabled')) {
  window.addEventListener('CookiebotOnLoad', init)
} else {
  init()
}

<template>
  <div v-if="!consentGiven">
    <h2>{{ dictionary.consentHeading }}</h2>
    <div v-html="vendorConsentText" />
    <Button is-close :text="consentButtonLabel" @close="handleContentButton" />
  </div>

  <div v-show="consentGiven" class="d365-form">
    <div
        :data-form-id="config.formId"
        :data-form-api-url="config.formApiUrl"
        :data-cached-form-url="config.cacheFormUrl" ></div>

    <ReCaptcha
      v-if="config.useCaptcha"
      :execute-switch="executeSwitch"
      :submit="submit"
      ref="recaptchaComponent"
    />
  </div>
</template>

<script setup>
import { onMounted, onUpdated, defineProps, ref, watch, nextTick } from 'vue';
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue';
import { getMarketingConsent } from '../../globals/cookiebot';
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager';
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs';
import Button from '../Button/Button.vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  config: {
    type: Object,
    required: true
  }
});

const vendor = cmpVendorIDs.GRECAPTCHA;
const consentGiven = ref(window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id));

const dictionary = window.dictionary;
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name);
const consentButtonLabel = dictionary.consentButtonLabel.replaceAll(/&apos;/g, "'");
const executeSwitch = ref(false);
const recaptchaComponent = ref(null);
const submitButton = ref(null);
const reCaptchaTriggered = ref(false);
const recaptchaToken = ref('');


const attachSubmitHandler = () => {
  nextTick(() => {
    let attempts = 0;
    const maxAttempts = 10;
    const checkInterval = 500;

    const findSubmitButton = setInterval(() => {
      const formElement = document.querySelector('[data-form-id]');
      if (formElement) {
        submitButton.value = formElement.querySelector('button[type="submit"]');

        if (submitButton.value) {
          submitButton.value.addEventListener("click", (event) => {
            if (reCaptchaTriggered.value) {
              console.warn("⚠️ Prevented duplicate ReCaptcha execution.");
              return;
            }
            event.preventDefault();
            reCaptchaTriggered.value = true;
            executeSwitch.value = true;
          });

          clearInterval(findSubmitButton);
        } else {
          console.warn(`⚠️ Submit button not found! Attempt ${attempts + 1}/${maxAttempts}`);
        }
      }

      attempts++;
      if (attempts >= maxAttempts) {
        clearInterval(findSubmitButton);
        console.error("❌ Submit button could not be found after multiple attempts.");
      }
    }, checkInterval);
  });
};


const submit = (token) => {
  recaptchaToken.value = token;
  if (submitButton.value) {
    submitButton.value.click();
  } else {
    console.warn("⚠️ Submit button not found!");
  }
};

let scriptsLoaded = false;

const loadD365FormScripts = async () => {
  if (scriptsLoaded) {
    return;
  }
  try {
    await loadScript(props.config.scriptFormLoaderUrl);
    await loadScriptTag(props.config.scriptLanguageCode);
    scriptsLoaded = true;
  } catch (error) {
    console.error('Failed to load D365 form scripts:', error);
  }
};

const loadScriptTag = (innerHTML) => {
  return new Promise((resolve, reject) => {
    document.querySelectorAll('script').forEach(x => { if (x.innerHTML == innerHTML)
      return resolve();
    });
    const script = document.createElement('script');
    script.innerHTML = innerHTML
    script.onload = () => {
      resolve();
    };
    script.onerror = (err) => {
      console.error(`Error loading script: ${innerHTML}`, err);
      reject(err);
    };
    document.body.appendChild(script);
  });
};


const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      return resolve();
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = (err) => {
      console.error(`Error loading script: ${src}`, err);
      reject(err);
    };
    document.body.appendChild(script);
  });
};

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id);
};

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListenersWithDelay(checkVendorConsent);
    checkVendorConsent();
    window.__cmp('setVendorConsent', [vendor.id, 1], () => {
      setTimeout(async () => {
        consentGiven.value = getVendorConsent(vendor.id);
        if (consentGiven.value) {
          await loadD365FormScripts();
          await nextTick();
        }
      }, 500);
    });
  } else {
    if (window.Cookiebot) {
      window.Cookiebot.onaccept = async () => {
        consentGiven.value = getMarketingConsent();
        if (consentGiven.value) {
          await loadD365FormScripts();
          await nextTick();
        }
      };
      window.Cookiebot.show();
    }
  }
};

const registerConsentListenersWithDelay = (checkVendorConsent) => {
  const intervalId = setInterval(() => {
    if (typeof window.__cmp === 'function') {
      registerConsentListeners(checkVendorConsent);
      clearInterval(intervalId);
    }
  }, 100);
  setTimeout(() => {
    clearInterval(intervalId);
  }, 5000);
};

onUpdated(() => {
  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListeners(checkVendorConsent)
    checkVendorConsent()
  }

  if (window.Cookiebot) {
    consentGiven.value = getMarketingConsent()
  } else {
    consentGiven.value = getVendorConsent(vendor.id)
  }
})

onMounted(async () => {
  if (consentGiven.value) {
    await loadD365FormScripts();
  }

  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListenersWithDelay(checkVendorConsent);
    setTimeout(() => {
      checkVendorConsent();
    }, 1000);
  }
  attachSubmitHandler();
});

watch(consentGiven, async (newVal) => {
  if (newVal) {
    await nextTick();
    await loadD365FormScripts();
    await nextTick();
  }
});
</script>
